import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import styled from 'styled-components'
import mq from '../styles/media-queries'

const H1 = styled.h1`
  color: var(--mainColor);
  text-transform:uppercase;
  font-weight: bold;
  margin-bottom: 0rem;
`

const H2 = styled.h2`
  color: var(--darkgrey);
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.6rem;
`

const H3 = styled.h3`
  color: var(--white);
  background: var(--mainColor);
  font-size: 1.4rem;

  color: rgb(255, 255, 255);
  display: inline-block;
  font-family: Lato-Regular, sans-serif;
  font-weight: bold;
  line-height: 1.4rem;
  letter-spacing: 1px;
  margin: 0px 0px 15px;
  padding: 10px 15px 8px;
  text-transform: uppercase;

  ${mq.md} {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
`

const P = styled.p`

`

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        case 'h1':
          return <H1>{props.children}</H1>
        case 'h2':
          return <H2>{props.children}</H2>
        case 'h3':
          return <H3>{props.children}</H3>

        default:
          return <p>{props.children}</p>
      }
    },
  }
}

const BlockContent = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />

export default BlockContent