import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Section, HeroSmallStyles, HR } from '../../styles/common/Common'
import { AlignCenter, Divider, IconWrapper, Item, ItemWrapper } from '../../styles/common/Element';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import mq from '../../styles/media-queries';

const BannerWrapper = styled.section`
  width: 100%;
  background-color: rgb(255, 255, 255);
  position: relative;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  background: -webkit-linear-gradient(left, rgb(218, 218, 218) 0%, rgb(149, 27, 129) 83%);
  height: inherit;
  display:flex;
  align-content: center;
  h1 {
    font-size: 2.3rem;
    font-weight: bold;
  }
  ${mq.md} {
    height: 225px;
  }
`

const AlignCenterWhite = styled(AlignCenter)`
  margin-top: inherit;
  margin-bottom: 0rem;
  h1, a, span {
    text-align:center;
    color: var(--white);
  }

  a {
    span {
      color: var(--white);
      font-size: 1.6rem;

      ${mq.md} {
        font-size: 2rem;
      }
    }
    &:after {
        background: var(--white);
      }
  }
`

const IconWrapperWhite = styled(IconWrapper)`
  color: var(--white);
  margin-bottom: 1rem;
`

const ItemNoPad = styled(Item)`
  padding: 0;
  margin-bottom: 20px;
  ${mq.md} {
    margin-bottom: 0px;
  }
`

export default function ContactBanner() {
  return (
    <BannerWrapper>
      <ItemWrapper>
        <ItemNoPad size={'50%'}>
          <AlignCenterWhite>
            <IconWrapperWhite>
              <FontAwesomeIcon icon={faPhoneAlt} size="4x" />
            </IconWrapperWhite>
            <h1>Rufen Sie uns an</h1>
            <a href="tel:+49-02865-2026291">
              <span>02865-2026291</span>
            </a>
          </AlignCenterWhite>
        </ItemNoPad>
        <ItemNoPad size={'50%'}>
          <AlignCenterWhite>
            <IconWrapperWhite>
              <FontAwesomeIcon icon={faEnvelope} size="4x" />
            </IconWrapperWhite>
            <h1>Schreiben Sie uns eine Mail</h1>
            <a href="mailto:info@physiotherapie-dumpe.de">
              <span>info@physiotherapie-dumpe.de</span>
            </a>
          </AlignCenterWhite>
        </ItemNoPad>
      </ItemWrapper>

    </BannerWrapper>
  )
}
